import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { BLUE } from "../constants"

import Layout from "../components/Layout/Layout"
import RelatedArticles from "../components/Articles/RelatedArticles"
import SubscriptionForm from "../components/FlexibleContent/SubscriptionForm/SubscriptionForm"

const NewsArticleTemplate = ({ data, location }) => {
  const {
    metaTitle,
    title,
    metaDescription,
    bodyRich,
    image,
    publishedDate,
    relatedArticles,
    newsCategory,
  } = data.craftNewsArticleDefaultEntry

  let imageData = {}
  if (image.length) {
    imageData = {
      image: getImage(image[0].localFile),
      alt: image[0].title,
    }
  }

  var options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  }

  const date = new Date(publishedDate)
  const formattedDate = date.toLocaleDateString("en-US", options)

  return (
    <Layout
      title={metaTitle || title}
      description={metaDescription}
      theme={BLUE}
      location={location}
    >
      <div className="js-transition-link-target" data-theme={BLUE}>
        {imageData.image && (
          <GatsbyImage
            className="h-0 pb-4:3 md:pb-2:5 w-full"
            image={imageData.image}
            alt={imageData.alt}
          />
        )}
        <div className="mx-ogs py-16 lg:py-24 2xl:py-32 md:grid md:grid-cols-12 md:gap-x-gs">
          <div className="md:col-span-10 md:col-start-2 lg:col-span-8 lg:col-start-3 2xl:col-span-6 2xl:col-start-4">
            {title && (
              <h1 className="font-semibold text-4xl lg:text-5xl 2xl:text-6xl text-blue-500 mb-4 lg:mb-6">
                {title}
              </h1>
            )}
            <div className="flex flex-wrap space-x-2 text-md">
              {formattedDate && (
                <div className="text-opacity-80">
                  Published on {formattedDate}
                </div>
              )}
            </div>
            {bodyRich && (
              <div
                className="rte mt-6 lg:mt-12"
                dangerouslySetInnerHTML={{ __html: bodyRich }}
              />
            )}

            {newsCategory.length > 0 && (
              <div className="flex space-x-3 mt-12">
                {newsCategory.map((item, index) => {
                  return (
                    <div
                      key={`${index} ${item.title}`}
                      className="font-semibold text-sm px-3 py-1.5 bg-blue-400 rounded-full"
                    >
                      {item.title}
                    </div>
                  )
                })}
              </div>
            )}
          </div>
          <RelatedArticles relatedArticles={relatedArticles} />
        </div>
        <SubscriptionForm />
      </div>
    </Layout>
  )
}

export default NewsArticleTemplate

export const query = graphql`
  query NewsArticleQuery($slug: String) {
    craftNewsArticleDefaultEntry(slug: { eq: $slug }) {
      id
      body
      metaTitle
      metaDescription
      bodyRich
      title
      image {
        ... on Craft_images_Asset {
          ...imageFragment
        }
      }
      publishedDate
      relatedArticles {
        ... on Craft_newsArticle_default_Entry {
          id
          title
          uri
          publishedDate
          image {
            ...imageFragment
          }
          body
          newsCategory {
            title
          }
        }
      }
      newsCategory {
        title
      }
    }
  }
`
