import React from "react"
import PropTypes from "prop-types"

import TransitionLinkFade from "../TransitionLink/TransitionLinkFade"

import NewsCard from "../News/NewsCard"
import EventCard from "../Events/EventsCard"

const RelatedArticles = ({ relatedArticles, isEvent = false }) => {
  if (relatedArticles.length === 0) return null
  return (
    <div className="mt-12 md:mt-24 2xl:mt-32 md:col-span-12 xl:col-span-10 xl:col-start-2">
      <h3 className="text-center text-xl lg:text-2xl font-semibold mb-12 text-blue-500">
        Related {isEvent ? "Events" : "Articles"}
      </h3>

      <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-gs">
        {relatedArticles.map((item, index) =>
          isEvent ? (
            <EventCard key={`${item.title}-${index}`} item={item} />
          ) : (
            <NewsCard key={`${item.title}-${index}`} item={item} />
          )
        )}
      </div>

      <div className="inline-flex justify-center mt-16 w-full">
        <TransitionLinkFade
          to={isEvent ? "/events" : "/articles"}
          className="Button Button--primary"
        >
          View all {isEvent ? "Events" : "Articles"}
        </TransitionLinkFade>
      </div>
    </div>
  )
}

RelatedArticles.propTypes = {
  isEvent: PropTypes.bool,
  articles: PropTypes.arrayOf(
    PropTypes.oneOf([
      PropTypes.shape({
        title: PropTypes.string,
        uri: PropTypes.string,
        publishedDate: PropTypes.string,
        body: PropTypes.string,
        image: PropTypes.arrayOf(PropTypes.shape({})),
        newsCategory: PropTypes.arrayOf(
          PropTypes.shape({
            title: PropTypes.string,
          })
        ),
      }),
      PropTypes.shape({
        title: PropTypes.string,
        uri: PropTypes.string,
        startDate: PropTypes.string,
        body: PropTypes.string,
        isOnline: PropTypes.bool,
        isFree: PropTypes.bool,
        image: PropTypes.string,
        address: PropTypes.shape({
          city: PropTypes.string,
          state: PropTypes.string,
          postCode: PropTypes.string,
        }),
      }),
    ])
  ),
}

export default RelatedArticles
